<template>
  <div>
    <screening :selected="queryParams.status" :states="states" @handleChange="handleChange"/>
    <div class="receipts-wrapper">
      <receipts v-for="item in certificateList" :receipts="item" :key="item.id" />
    </div>
    <div class="footer">
      <pagination :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
                  @pagination="getStudentCertificate"/>
    </div>
  </div>
</template>

<script>
import {getStudentCertificate} from "@/api/bm/student";
import receipts from '../components/receipts/index.vue';
import screening from '../components/screening/index.vue'
import pagination from '@/components/Pagination/index.vue'

export default {
  components: {receipts, screening,pagination},
  data() {
    return {
      queryParams: {pageNum: 1, pageSize: 20, status: ''},
      certificateList: [],
      total: 0,
      states: [
        {label: '全部', value: ''},
        {label: '待审核', value: '0'},
        {label: '审核通过', value: '1'},
        {label: '证书已邮寄', value: '2'},
      ]
    }
  },
  methods: {
    async getStudentCertificate() {
      const {rows, total} = await getStudentCertificate(this.queryParams)
      this.certificateList = rows
      this.total = total
    },
    handleChange(e) {
      this.queryParams.status = e
      this.queryParams.pageNum = 1
      this.getStudentCertificate()
    }
  },
  mounted() {
    this.getStudentCertificate()
  }
}
</script>

<style scoped lang="scss">
.receipts-wrapper{
  padding-top: 32px;
}
.footer{
  display: flex;
  justify-content: flex-end;
  padding-right: 20%;
}
</style>
