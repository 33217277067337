<template>
  <div class="dropdown-set-group" v-if="receipts">
    <div class="dropdown-set-btn" @click="DropdownToggle">
      <div class="trunk-wrapper">
        <div class="title">{{ receipts.courseName }}</div>
        <div class="audit-status"
             :class="{'warn':receipts.status==='0','pass':receipts.status==='2','succeed':receipts.status==='1'}">
          {{ returnLabel(receipts.status) }}
        </div>
        <div class="application-date">申请时间: {{ receipts.applicationTime }}</div>
      </div>
      <div v-if="receipts.status!=='0'">
        <i class="el-icon-arrow-down expand-more animation" :class="{'active': Dropdown}"></i>
      </div>
    </div>
    <div class="dropdown-set-content-area animation" :class="{'active': Dropdown}"
         :style="{'max-height' : !Dropdown ? 0 : divHeight + 'px'}">
      <div class="dropdown-set-content" id="dropdown">
        <div class="content">
          <div class="item">
            <div class="label">审核时间</div>：
            <div class="details">{{ receipts.checkTime }}</div>
          </div>
          <div class="item attention" v-if="receipts.status === '1'">
            <div class="label">注意</div>
            ：
            <div class="details">证书审核已通过，我们会在1个月内制定培训证书，请耐心等候证书发放！</div>
          </div>
          <div class="item" v-if="receipts.status === '2'">
            <div class="label">证书编号</div>
            ：
            <div class="details">{{ receipts.certificateNumber }}</div>
          </div>
          <div class="item" v-if="receipts.status === '2'">
            <div class="label">物流公司</div>
            ：
            <div class="details">{{ receipts.expressCompany }}</div>
          </div>
          <div class="item" v-if="receipts.status === '2'">
            <div class="label">快递单号</div>
            ：
            <div class="details">{{ receipts.trackingNumber }}</div>
          </div>
          <div class="item" v-if="receipts.status === '2'">
            <div class="label">发送时间</div>
            ：
            <div class="details">{{ receipts.releaseTime }}</div>
          </div>
          <div class="item attention" v-if="receipts.status === '2'">
            <div class="details">
              具体物流信息可在中国邮政速递物流官网
              <el-link href="https://www.ems.com.cn/" type="primary" target="_blank"> www.ems.com.cn </el-link>
              进行查询
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    receipts: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      Checked: true,
      Dropdown: false,
      divHeight: "",
      options: [
        {label: '待审核', value: '0'},
        {label: '审核通过，证书待发放', value: '1'},
        {label: '证书已邮寄', value: '2'}
      ]
    }
  },
  methods: {
    DropdownToggle: function (ele) {
      if (this.receipts.status === '0') return
      this.Dropdown = !this.Dropdown;
    },
    returnLabel(e) {
      const arr = this.options.find(item => item.value === e)
      return arr.label
    }
  },
  mounted() {
    this.divHeight = this.$el.querySelector("#dropdown").clientHeight;
  }
}
</script>

<style scoped lang="scss">
.animation {
  transition: 0.5s;
}

.switch-button {
  .switch-button-icon {
    position: relative;
    margin: 0;
    cursor: pointer;

    .switch-bar {
      width: 40px;
      height: 10px;
      border-radius: 6px;
      background-color: #cfcfcf;
    }

    .switch-control {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 20px;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: #a5a5a5;
      transition: 0.5s;
    }
  }

  .switch-txt {
    color: black;
    margin-left: 0.625rem;
  }

  .form-check-input:checked ~ .switch-button-icon {
    .switch-control {
      right: 0;
      background-color: red;
      border: none;
    }
  }
}

.dropdown-set-group {
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 80%;
  margin-bottom: 20px;

  .dropdown-set-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    cursor: pointer;
    background: #F9FAFB;

    .trunk-wrapper {
      width: 96%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;

      .title {
        flex: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .audit-status {
        flex: 1;

        &.warn {
          color: #FBBF24;
        }

        &.succeed {
          color: #34D399;
        }

        &.pass {
          color: #60A5FA;
        }
      }
    }

    .expand-more {
      color: #a5a5a5;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-set-content-area {
    border-top: 1px solid #eeeeee;
    overflow: hidden;
    visibility: hidden;

    &.active {
      visibility: visible;
    }

    .dropdown-set-content {
      padding: 24px 15px;
    }

    .content {
      font-size: 14px;

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        .label {
          width: 56px;
          text-align-last: justify;
        }
        .detail{
          display: flex;
          align-items: center;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &.attention {
          color: #FBBF24;
        }
      }
    }
  }
}
</style>
